if (document.querySelector('.tabs')) {
  const tabs = document.querySelector('.content_section');
  const tabButtons = tabs.querySelectorAll('[role="tab"]');
  const tabPanels = tabs.querySelectorAll('[role="tabpanel"]');

  const metaContent = {
    beauty: {
      title: 'Система онлайн-записи для салонов красоты',
      description: 'Онлайн-запись для салона красоты. Электронная запись к парикмахеру. Автоматизация бизнеса.',
      keywords: 'онлайн запись в салон, автоматизация бизнеса, система онлайн записи',
      url: '/products/online-booking/onlayn-zapis-dlya-salonov-krasoty'
    },
    medicine: {
      title: 'Система онлайн-записи к врачу',
      description: 'Автоматизация записи для медицинских клиник. Запись на прием к врачу. Онлайн календарь.',
      keywords: 'онлайн запись, croinc, онлайн запись на фитнес, автоматизация бизнеса',
      url: '/products/online-booking/onlayn-zapis-k-vrachu'
    },
    fitness: {
      title: 'Система онлайн-записи для фитнеса',
      description: 'Онлайн запись Croinc. Автоматизация фитнес центов, спортзалов и бизнеса. Программа для записи на тренировки.',
      keywords: 'онлайн запись, croinc, онлайн запись на фитнес, автоматизация бизнеса',
      url: '/products/online-booking/onlayn-zapis-dlya-fitnesa'
    }
  };

  const urlsArray = [
    {url: '/products/online-booking/onlayn-zapis-dlya-salonov-krasoty/', key: 'beauty'},
    {url: '/products/online-booking/onlayn-zapis-k-vrachu/', key: 'medicine'},
    {url: '/products/online-booking/onlayn-zapis-dlya-fitnesa/', key: 'fitness'}
  ];

  function handleTabClick(event) {
    setCurrentTab(event.currentTarget);
    setMetaTags( event.currentTarget.getAttribute('data-cat') );
  }//

  window.addEventListener('popstate', function () {
    initTabs();
  });

  function setCurrentTab(event) {
    tabPanels.forEach(function(panel) {
      panel.hidden = true;
    });
    tabButtons.forEach(function(tab) {
      tab.setAttribute('aria-selected', false);
    });
    event.setAttribute('aria-selected', true);
    const {id} = event;
    const tabPanel = tabs.querySelector(`[aria-labelledby="${id}"]`);
    tabPanel.hidden = false;
    document.querySelectorAll('.category_heading').forEach(function (cat) {
      if (cat.getAttribute('data-cat_heading') ===  event.getAttribute('data-cat')) {
        let currentPosition = document.createElement('h1');
        triggerElements(cat, currentPosition);
      } else {
        let prependElement = document.createElement('div');
        triggerElements(cat, prependElement);
      }
    });
  }

  tabButtons.forEach(button => button.addEventListener('click', handleTabClick));
   function initTabs() {
    let currentTabPage = window.location.pathname;
     window.history.pushState({ }, null, currentTabPage);
     urlsArray.forEach( cat => {
       if (cat.url === currentTabPage || cat.url === `${currentTabPage}/`) {
         setMetaTags( cat.key );
         setCurrentTab(document.querySelector(`[data-cat=${cat.key}]`));
       }
     });
  }//

  initTabs();

  function setMetaTags ( cat ) {
    document.querySelector('title').innerText = metaContent[cat].title;
    document.querySelector('meta[name=description]').content = metaContent[cat].description;
    document.querySelector('meta[name=keywords]').content = metaContent[cat].keywords;
    window.history.pushState({ category: metaContent[cat].url }, null, metaContent[cat].url);
  }

}//


function triggerElements(cat, prependElement) {
  let currentCat = cat.getAttribute('data-cat_heading');
  let  classNames = cat.className;
  prependElement.innerHTML = cat.innerHTML;
  prependElement.classList = classNames;
  prependElement.setAttribute('data-cat_heading', currentCat);
  cat.parentNode.replaceChild(prependElement, cat);
}

