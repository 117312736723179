//Импорт аналитики и хелперов
if(window.location.hostname !== 'localhost') {
  //Yandex Metrika counter
  (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments);};
    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a);})
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  ym(55013071, "init", {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true
  });
//Yandex.Metrika counter

  // Facebook Pixel Code
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '3053928414877557');
    fbq('track', 'PageView');
 //End Facebook Pixel Code -->

  //Begin Talk-Me {literal}
  (function(d, w, m) {
    window.supportAPIMethod = m;
    var s = d.createElement('script');
    s.type ='text/javascript'; s.id = 'supportScript'; s.charset = 'utf-8';
    s.async = true;
    var id = '8a37c25ba0f75606624286fe8ce01e38';
    s.src = 'https://lcab.talk-me.ru/support/support.js?h='+id;
    var sc = d.getElementsByTagName('script')[0];
    w[m] = w[m] || function() { (w[m].q = w[m].q || []).push(arguments); };
    if (sc) sc.parentNode.insertBefore(s, sc);
    else d.documentElement.firstChild.appendChild(s);
  })(document, window, 'TalkMe');
// {/literal} End Talk-Me

}

import '../styles/index.scss';
import './Carousel.js';
import './Tabs.js';

import '../assets/tiny-slider/tiny-slider.js';
import '../assets/tiny-slider/tiny-slider.css';
import {tns} from 'tiny-slider';

// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.css';


//mobile menu
if(document.querySelector('#mobile_menu__trigger')) {
  document.querySelector('#mobile_menu__trigger').addEventListener('click', function (e) {
    document.querySelector('.collapse_menu').classList.toggle('left-open');
    document.body.classList.toggle('menu_is_open');
  });
  document.querySelector('#backBtn').addEventListener('click', function (e) {
    closeMobileMenu();
  });

  document.querySelectorAll('.collapse_menu a').forEach(function (link) {
    link.addEventListener('click', function (e) {
      closeMobileMenu();
    });
  });
}


function closeMobileMenu() {
  document.querySelector('.collapse_menu').classList.remove('left-open');
  document.body.classList.remove('menu_is_open');
}

function clearClasses() {
  document.querySelectorAll('.nav_block a').forEach(cl => cl.classList.remove('active'));
}

/* Удаляем ссылку из элемента, если находимся на этом адресе ( '/product' === '/product') */
function delCurrentLink(link) {
  let classNames = link.className;
  if(link.hasAttribute('href')) {
    link.removeAttribute('href');
    let currentPosition = document.createElement('span');
    currentPosition.innerHTML = link.innerHTML;
    currentPosition.classList = classNames;
    link.parentNode.replaceChild(currentPosition, link);
  }
}

/* Удаляем из главного и бокового меню*/
document.querySelectorAll('.nav_block a').forEach(function (link) {
  link.addEventListener('click', function (e) {
    clearClasses();
    setTimeout(() => {
      if (link.getAttribute('href') === window.location.pathname) {
        link.classList.add('active');
      }
    }, 300);
  });
  setTimeout(() => {
    if (link.getAttribute('href') === window.location.pathname || link.getAttribute('href') === window.location.pathname.slice(0, -1)) {
      link.classList.add('active');
      delCurrentLink(link);
    }
  }, 300);
});

/* Удаляем из лого*/
if(window.location.pathname  === '/') {
  document.querySelectorAll('.logo_link').forEach(function(logoLink) {
    delCurrentLink(logoLink);
  });
}

//Tariffs
//стоимость каждого тарифа в месяц по порядку в масиве
let monthPriceTariffs = [500, 1000];
//Стандартное значение количества месяцев при загрузки (3,6,12);
let defaultMonthInPage = 6;
let tariffElement = document.querySelectorAll('[data-tariff]');
if (tariffElement) {
  setPrice(defaultMonthInPage);
  let radios = document.querySelectorAll('input[type=radio][name="tar_duration"]');
  radios.forEach(radio => radio.addEventListener('change', () => {
    setPrice(radio.value);
  }));

  //Установка значений цены для каждого тарифа, согласно количеству месяцев.
  function setPrice(month) {
    document.querySelectorAll('[data-tariff=standard]').forEach(el => el.innerHTML = parseInt(month) * parseInt(monthPriceTariffs[0]) + '₽');
    document.querySelectorAll('[data-tariff=pro]').forEach(el => el.innerHTML = parseInt(month) * parseInt(monthPriceTariffs[1]) + '₽');
    document.querySelectorAll('div[data-month]:not([data-month="'+month+'"])').forEach(el => el.style.display = 'none');
    document.querySelectorAll('div[data-month="'+month+'"]').forEach(el => el.style.display = 'block');
  }
}


//Swiper
if (document.querySelector('.swiper-container')) {
  let featured_block = new Swiper('.swiper-container', {
    slidesPerView: 1,
    spaceBetween: 24,
    centeredSlides: false,
    freeMode: false,
    freeModeSticky: false,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    loop: false,
    breakpoints: {
      998: {
        slidesPerView: "auto"
      }
    }
  });
}

if (document.querySelector('.swiper-container-tabs')) {
  let tabs = new Swiper('.swiper-container-tabs', {
    slidesPerView: 1,
    spaceBetween: 24,
    centeredSlides: false,
    freeMode: false,
    freeModeSticky: false,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    loop: false,
    breakpoints: {
      998: {
        slidesPerView: 2
      },
      1439: {
        slidesPerView: 4
      }
    }
  });
}

/*Валидация форм - проверка на согласия обработки данных*/
if( document.getElementById('confidential')) {
  const elTrigger = document.getElementById('confidential');
  const sendBtn = document.getElementById('sendMessage');
  elTrigger.addEventListener('change', (event) => {
      sendBtn.disabled = !event.target.checked;
  });
}

if( document.getElementById('confidentialCall')) {
  const elTrigger = document.getElementById('confidentialCall');
  const sendBtn = document.getElementById('sendProof');
  elTrigger.addEventListener('change', (event) => {
    sendBtn.disabled = !event.target.checked;
  });
}


if( document.querySelectorAll('.order_call') && document.getElementById('callBackModal') ) {
  let modalElement =  document.getElementById('callBackModal');

  let triggerEle = document.querySelectorAll('.order_call');
  triggerEle.forEach( el => el.addEventListener( 'click', ()=> {
    modalElement.classList.remove('hidden');
    document.body.classList.add('has_overlay');
  }));

  if (document.getElementById('closeCallModal')) {
    document.getElementById('closeCallModal').onclick = function () {
      modalElement.classList.add('hidden');
      document.body.classList.remove('has_overlay');
    };
  }

}





